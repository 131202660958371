import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Header from "../components/Header";
import Footer from "../components/Footer";
import "./index.css";
import favicon from "../images/logo.png";

const TemplateWrapper = ({ children, location }) => (
  <div>
    <Helmet
      title="Möhl GmbH & Co. KG"
      meta={[{ name: "description", content: "Möhl GmbH & Co. KG" }]}
    >
      <link rel="icon" href={favicon}></link>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: "Möhl GmbH & Co. KG",
          image: { favicon },
          "@id": "",
          url: "https://www.moehl-koeln.de/",
          telephone: "0221 681066",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Möhlstraße 24",
            addressLocality: "Köln",
            postalCode: "51069",
            addressCountry: "DE",
          },
          openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: ["Friday", "Thursday", "Wednesday", "Tuesday", "Monday"],
            opens: "09:00",
            closes: "12:30",
          },
        })}
      </script>
    </Helmet>
    <Header location={location} />
    <div>{children}</div>
    <Footer />
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.func,
};

export default TemplateWrapper;
