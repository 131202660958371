import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-sm-3">
          <h4>Anfahrt</h4>
          <p>
            Möhl GmbH & Co. KG
            <br />
            Möhlstraße 24
            <br />
            51069 Köln
          </p>
        </div>
        <div className="col-sm-3">
          <h4>Öffnungszeiten</h4>
          <p>
            Montag bis Freitag:
            <br />
            9:00 bis 12:30
          </p>
        </div>
        <div className="col-sm-3">
          <h4>Kontakt</h4>
          <p>
            Telefon: <a href="tel:+49221681066">0221 / 68 10 66</a>
            <br />
            Telefax: <a href="tel:+492216804760">0221 / 680 47 60</a>
            <br />
            E-Mail: <a href="mailto:info@moehl-koeln.de">info@moehl-koeln.de</a>
          </p>
        </div>
        <div className="col-sm-3">
          <h4>Rechtliches</h4>
          <a href="/imprint" className="active">
            Impressum
          </a>
          <br />
          <a href="/privacy" className="active">
            Datenschutz
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
