import React from 'react';
import { Link, navigate, withPrefix } from 'gatsby';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

import Logo from '../../images/logo.jpg';

const Header = ({ location }) => (
  <div>
    <Navbar fixedTop collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/" exact activeClassName="active">
            <img src={Logo} alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <NavItem
            eventKey={1}
            href="/"
            active={location.pathname === withPrefix('/')}
            onClick={e => {
              e.preventDefault();
              navigate('/');
            }}
          >
            Startseite
          </NavItem>
          <NavItem
            eventKey={2}
            href="/about"
            active={location.pathname === withPrefix('/about')}
            onClick={e => {
              e.preventDefault();
              navigate('/about');
            }}
          >
            Unternehmensgeschichte
          </NavItem>
          <NavItem
            eventKey={3}
            href="/contact"
            active={location.pathname === withPrefix('/contact')}
            onClick={e => {
              e.preventDefault();
              navigate('/contact');
            }}
          >
            Kontakt
          </NavItem>
          <NavItem
            eventKey={4}
            href="/downloads"
            active={location.pathname === withPrefix('/downloads')}
            onClick={e => {
              e.preventDefault();
              navigate('/downloads');
            }}
          >
            Downloads
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    {/* <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            aria-expanded="false"
            className="navbar-toggle collapsed"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <a href="/" className="navbar-brand">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <div id="navbar-collapse" className="collapse navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/" exact activeClassName="active">
                Startseite
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName="active">
                Unternehmensgeschichte
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName="active">
                Kontakt
              </Link>
            </li>
            <li>
              <Link to="/downloads" activeClassName="active">
                Downloads
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav> */}
    <div className="spacer-nav" />,
  </div>
);

export default Header;
